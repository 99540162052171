import { GoogleAuthProvider, TwitterAuthProvider, getAuth, signInWithPopup } from 'firebase/auth';
import { firebaseApp } from '../classes/code/Firebase';
import React, { MouseEvent, useEffect, useState } from 'react';
import { Modal } from './SettingsPanel/Modal';
import styles from './UserPanel.module.css';

import { GithubAuthProvider } from 'firebase/auth';
import { selectProjectStatus } from '../store/projectSlice';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faGoogle, faXTwitter, } from '@fortawesome/free-brands-svg-icons';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { logError, logMajorComponentRender } from '../classes/Logger';
import { Stack } from './Stack';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { selectUser, userSet } from '../store/uiSlice';
import { setRouteHome } from '../App';

// Admin users
// Use firestore to check uid : https://firebase.blog/posts/2022/09/announcing-cross-service-security-rules
// Add custom claims : https://medium.com/firebase-tips-tricks/how-to-create-an-admin-module-for-managing-users-access-and-roles-34a94cf31a6e

export const UserPanel: React.FC = (props) => {

    logMajorComponentRender(UserPanel.name);

    const navigate = useNavigate();

    const { isCloud, dirty } = useAppSelector(selectProjectStatus);

    const [signInModalIsOpen, SetSignInModalIsOpen] = useState<boolean>(false);
    const [confirmationModalIsOpen, SetConfirmationModalIsOpen] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    const { uid, userName } = useAppSelector(selectUser);

    const handleCloseSignInModal = () => {
        SetSignInModalIsOpen(false);
    }

    const handleCloseConfirmationModal = () => {
        SetConfirmationModalIsOpen(false);
    }

    const handleOpenSignInPanel = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        SetSignInModalIsOpen(true);
    }

    useEffect(() => {
        const unsubscribe = getAuth(firebaseApp).onAuthStateChanged(authUser => {

            let uid: string | undefined;
            let userName: string | undefined;

            if (authUser != null) {
                uid = authUser.uid;
                // @ts-ignore: missing type
                userName = authUser.reloadUserInfo.screenName ?? authUser.displayName; // screenName doesn't seem to exist for google accounts
            }

            dispatch(userSet({ uid, userName }))
        });
        return () => unsubscribe();
    }, [dispatch]);

    const handleSignOut = async (e: MouseEvent) => {

        e.preventDefault();
        e.stopPropagation();

        // We're working on a local file, so it doesn't matter whether we're logged in or not
        if (!isCloud) {
            const auth = getAuth(firebaseApp);
            await auth.authStateReady();
            await auth.signOut();
            setRouteHome(navigate);
            return;
        }

        // We're working on a cloud file but there are no changes to save so it's safe to log out
        // Reset the project when we've logged out
        if (!dirty) {
            const auth = getAuth(firebaseApp);
            await auth.authStateReady();
            await auth.signOut();
            setRouteHome(navigate);
            return;
        }

        // We're working on a cloud file and there are changes which need saving, so work could be lost
        SetConfirmationModalIsOpen(true);
    }

    const handleForceSignOut = async () => {
        const auth = getAuth(firebaseApp);
        await auth.authStateReady();
        await auth.signOut();
        SetConfirmationModalIsOpen(false);
        setRouteHome(navigate);
    }

    const handleGithubSignIn = async () => {
        try {
            const provider = new GithubAuthProvider();
            provider.addScope('user');
            provider.setCustomParameters({ 'allow_signup': 'false' });
            const auth = getAuth();
            await auth.authStateReady();
            await signInWithPopup(auth, provider);
            handleCloseSignInModal();
        } catch (error) {
            logError(String(error));
        }
    };

    const handleXSignIn = async () => {
        try {
            const provider = new TwitterAuthProvider();
            const auth = getAuth();
            await auth.authStateReady();
            await signInWithPopup(auth, provider);
            handleCloseSignInModal();
        } catch (error) {
            logError(String(error));
        }
    };

    const handleGoogleSignIn = async () => {
        try {
            const provider = new GoogleAuthProvider();
            provider.addScope('https://www.googleapis.com/auth/userinfo.email');
            provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
            provider.setCustomParameters({ 'allow_signup': 'false' });
            const auth = getAuth();
            await auth.authStateReady();
            await signInWithPopup(auth, provider);
            handleCloseSignInModal();
        } catch (error) {
            logError(String(error));
        }
    };

    const signedIn = (uid != null);
    const displayName = signedIn ? `Hi ${userName}` : '(no name)';

    return (
        <div className={styles.panel}>
            {signedIn &&
                <Stack layout='horizontal' sizes='1fr auto' alignItems='center'>
                    <div className={styles.name}>{displayName}</div>
                    <button onClick={handleSignOut} className={styles.button}>Sign out</button>
                </Stack>
            }

            {!signedIn &&
                <Stack layout='horizontal' sizes='1fr auto' alignItems='center'>
                    <div className={styles.name}><span className={styles.detail}>Not signed in</span></div>
                    <button onClick={handleOpenSignInPanel} className={styles.button}>Sign up/in...</button>
                </Stack>
            }

            {signInModalIsOpen &&
                <Modal onClose={handleCloseSignInModal} align='bottom-right'>
                    <div className={styles.popup}>
                        <Stack layout='vertical'>
                            <button onClick={handleGoogleSignIn} className={`${styles.signInButton} ${styles.google}`}><FontAwesomeIcon icon={faGoogle} /> Sign in with Google</button>
                            <button onClick={handleGithubSignIn} className={`${styles.signInButton} ${styles.github}`}><FontAwesomeIcon icon={faGithub} /> Sign in with GitHub</button>
                            <button onClick={handleXSignIn} className={`${styles.signInButton} ${styles.x}`}><FontAwesomeIcon icon={faXTwitter} /> Sign in with X</button>
                        </Stack>
                    </div>
                </Modal>
            }

            {confirmationModalIsOpen &&
                <Modal onClose={handleCloseConfirmationModal} align='bottom-right'>
                    <div className={styles.popup}>
                        <p><FontAwesomeIcon icon={faWarning} /> Signing out now will lose unsaved changes</p>
                        <Stack layout='horizontal' justifyContent='end'>
                            <button onClick={handleCloseConfirmationModal} className={styles.button}>Cancel</button>
                            <button onClick={handleForceSignOut} className={styles.button}>Sign out</button>
                        </Stack>
                    </div>
                </Modal>
            }
        </div>
    );
}