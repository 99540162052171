import styles from './HomePage.module.css';
import imageCreatures from '../images/homepage-creatures.png';
import imageGiana from '../images/homepage-giana.png';
import imageDisassembly from '../images/homepage-disassembly.png';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faFloppyDisk, faCloud, IconDefinition, faCopy, faChevronDown, faArrowRight, faPlus } from '@fortawesome/free-solid-svg-icons';
import { cx } from '../classes/Utils';
import { faEye, faEyeSlash, faTrashCan } from '@fortawesome/free-regular-svg-icons';

export const Banner: React.FC<{ message: string, colours: string[], background: string, updateMS: number }> = ({ message, colours, background, updateMS }) => {
    const [text, SetText] = useState(<>{message}</>);

    useEffect(() => {

        let t: NodeJS.Timeout;
        let index = 0;

        const callback = () => {

            const front = message.slice(index);
            const back = message.slice(0, index);
            index++;
            if (index === message.length)
                index = 0;

            const scrolledText = [...`< ${front}${back} >`].map((l, i) => {
                const colIndex = i % colours.length;
                const colourName = `--colour-${colours[colIndex]}`;
                return <span key={i} style={{ color: `var(${colourName})`, backgroundColor: `var(--colour-${background})` }}>{l}</span>
            });

            SetText(<>{scrolledText}  </>)

            t = setTimeout(callback, updateMS);
        }

        callback();

        return () => clearTimeout(t);
    }, [SetText, message, colours, background, updateMS]);

    return <>{text}</>;
}

const wrap = (text: string, iconBefore?: IconDefinition, iconAfter?: IconDefinition, extraStyle?: string) => {
    const iconBeforeJsx = iconBefore == null ? undefined : <FontAwesomeIcon className={styles.icon} icon={iconBefore} />;
    const iconAfterJsx = iconAfter == null ? undefined : <FontAwesomeIcon className={styles.icon} icon={iconAfter} />;
    return <span className={cx(styles.labelWithIcon, extraStyle)}>{iconBeforeJsx}{text}{iconAfterJsx}</span>
}

const makeCopyToCloud = () =>
    <span className={styles.labelWithIcon}>
        <FontAwesomeIcon className={styles.copyIconFix} icon={faCopy} />
        <FontAwesomeIcon className={styles.copyIconFix} icon={faArrowRight} />
        <FontAwesomeIcon className={styles.copyIconFix} icon={faCloud} />
    </span>;

const makeCopyToLocal = () =>
    <span className={styles.labelWithIcon}>
        <FontAwesomeIcon className={styles.copyIconFix} icon={faCopy} />
        <FontAwesomeIcon className={styles.copyIconFix} icon={faArrowRight} />
        <FontAwesomeIcon className={styles.copyIconFix} icon={faFloppyDisk} />
    </span>

const makeFilter = () =>
    <span className={styles.filterIcon}>
        <span>E</span>
        <input type='checkbox' readOnly={true} checked={true} />
    </span>

export const HomePage: React.FC = () => {

    const title = '46c - reversing the C64';
    if (window.document.title !== title) {
        window.document.title = title;
    }

    return (
        <div className={styles.container}>
            <div className={styles.column}>

                <h2><Banner message={'** HELLO WORLD! '} colours={['04', '0D', '0F', '07']} background={'0A'} updateMS={250} /></h2>

                <h1>What</h1>

                <h3>
                    46C is a web-app for 'reverse engineering' Commodore 64 programs and games - exploring and documenting how software worked on one of the most popular machines of home computing's golden age.
                </h3>

                <p>
                    It presents RAM as the VIC-II would interpret it for character mapped, bitmapped and sprite graphics and lets you 'rip' graphics to a library when interesting areas are discovered. It disassembles 6510 code at lightning speed from given entry points, which can then be interactively marked-up with labels, comments, formatting and other hints.
                </p>

                <p>
                    It saves projects to browser storage by default, and can save to the cloud if signed in with an existing Google, Github or X/Twitter account.
                </p>

                <img src={imageCreatures} alt='' />

                <h1>How</h1>

                <p>
                    Proper instructions and documentation coming soon - follow <a href='https://x.com/46cio' target='_blank' rel="noreferrer">@46cio</a> for updates! Until then :
                </p>

                <p>
                    <span className={styles.tabHeading}>Get started</span> : open the {wrap('Project', undefined, faChevronDown, styles.projectIconFix)} menu at the top of the page, switch to the {wrap('Public', faEye, undefined, styles.public)} tab and open one of these work-in-progress projects. This read-only view of a project lets you see what others have discovered and can be a great starting point for your explorations. Click on {wrap('Clone to edit', faCopy)} to make your own editable copy of the project, which will be saved to browser storage ({wrap('Mine', faFloppyDisk)}) or the cloud ({wrap('Mine', faCloud)}) depending on whether or not you are signed in (don't worry - you can always move projects between the two later with {makeCopyToCloud()} or {makeCopyToLocal()}).
                </p>
                <p>
                    If you want to explore a game not already shared by another user, open your choice in <a href='https://vice-emu.sourceforge.io/index.html#download' target='_blank' rel="noreferrer">Vice emulator</a> (preferably the recent version 3.8) and make a snapshot. This will save a .vsf file, which you can open in 46C by clicking on the {wrap('Project', undefined, faChevronDown, styles.projectIconFix)} menu then {wrap('Add from file', faPlus)}. Or just get in touch and I'll see what I can do!
                </p>

                <p>
                    When you're ready to share your progress with the world, make sure you're signed in and your project is saved to the cloud - then simply change {wrap('Private', faEyeSlash, undefined)} to {wrap('Public', faEye, undefined, styles.publicStatus)} next to the project name and save. Now others will see your project under the {wrap('Public', faEye, undefined, styles.public)} tab, and you can also share the url of the project (eg <a href='https://46c.io/project/ME7B6E9L' target='_blank' rel='noreferrer'>https://46c.io/project/ME7B6E9L</a>).
                </p>

                <p>
                    <span className={styles.tabHeading}>Graphics</span> : play with the mode settings along the top of the window to identify interesting things lurking in memory. In character-mapped view, clicking on a character set will draw all character-mapped screens using that set; in bitmapped view clicking on a colour bank will draw all bitmaps using those colours. If you're not too familiar with C64 graphic modes, sprites are probably the easiest to start with.
                </p>

                <p>
                    When you have a good-looking preview in the resizable panel on screen left, click {wrap('Rip')} to save it to the <span className={styles.tabHeading}>Library</span> (it will also be visible at its address in memory when viewing <span className={styles.tabHeading}>Code</span>);
                </p>

                <p>
                    <span className={styles.tabHeading}>Code</span> : the general idea is to select either a single byte or a range (using shift+click to extend your selection) then apply markup using the options along the top of the screen. Memory is fully disassembled from scratch after each change, so it's very quick and easy to uncover code by toggling entry points. You can also toggle between viewing RAM and ROM (+ I/O).
                </p>

                <p>
                    All of your tweaks can be seen in the resizable {wrap('Markup')} panel on screen left. You can delete markup by clicking on {wrap('', faTrashCan, undefined, styles.singleIconFix)} at the start of a row, filter shown markup types with the checkboxes like {makeFilter()} near the top of the panel and use the search box to find your comments, labels and other named markup.
                </p>

                <p>
                    Pro-tip : you can also search for byte sequences like {wrap('8d 21 d0')} to find these in memory! There's lots more to discover...
                </p>

                <img src={imageGiana} alt='' />

                <h1>Why</h1>

                <p>
                    This kind of code archaeology lets you play digital detective, hunting down meaning in a sea of hexadecimal, following the footsteps and decisions of fellow coders from a different time and ultimately unpicking a puzzle created 40-odd years ago. The scale of 8-bit games is perfect : the platform's constraints led to some truly fascinating techniques, while the programs are often small enough to understand in their entirety.
                </p>

                <p>
                    Many great tools have been created over the years for reverse engineering the C64. 46C is an attempt at a modern, accessible and immediate way into this incredibly fun and satisfying hobby.
                </p>

                <img src={imageDisassembly} alt='' />

                <h1>Who</h1>

                <p>
                    46c is the work of <a href='https://linkedin.com/in/robindeitch' target='_blank' rel="noreferrer">Robin Deitch</a> and reflects a multi-decade fascination with the 8-bit world.
                </p>

                <p>
                    Stay in touch for updates, bug reporting, suggestions and human contact : <a href='https://x.com/46cio' target='_blank' rel="noreferrer">@46cio</a> / <a href='mailto:hello@46c.io' target='_blank' rel="noreferrer">hello@46c.io</a>.
                </p>

                <p>
                    Enjoy! <FontAwesomeIcon icon={faHeart} color='red' />
                </p>
            </div>
        </div >
    );
}