import styles from './ProjectPage.module.css';
import { ViewportLocation } from "../store/rendererSlice";
import { GraphicsViewport } from "./graphics/GraphicsViewport";
import { MainPanel } from "./MainPanel";
import { MemoryMap } from "./MemoryMap";
import { ProjectNamePanel } from "./ProjectNamePanel";
import { SavePanel } from "./SavePanel";
import { SettingsPanel } from "./SettingsPanel";
import { Stack } from "./Stack";
import { TabsPanel } from "./TabsPanel";
import { LoaderFunctionArgs, Outlet, useLoaderData, useLocation, useNavigate, useParams } from 'react-router-dom';
import { descriptionSet, selectGuid } from '../store/projectSlice';
import { getAsync as getAsyncCloud } from '../classes/CloudStorageHelper';
import { exists, getAsync as getAsyncLocal } from '../classes/LocalStorageHelper';
import { CSSProperties, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { logError, logInfo, logMajorComponentRender } from '../classes/Logger';
import { checkGuid, makeGuid, ProjectDescription } from '../classes/ProjectDescription';
import { activeToolSet, selectActiveTool, ToolTypes } from '../store/toolSlice';
import { activeMemoryViewSet, MemoryViewType, selectActiveMemoryView } from '../store/codeSlice';
import { selectIsProjectReadOnly } from '../store/extraSelectors';
import { setRoute } from '../App';
import { PrepareCommands } from '../classes/TestData';
import { newProjectDescription } from '../classes/ProjectDescriptionHelpers';

export const colourUnknown = [82, 82, 82];
export const colourGfx = [255, 209, 102];
export const colourCode = [6, 214, 160];
export const colourData = [17, 138, 178];


const sourceColoursStyle: CSSProperties = {
    ['--colour-unknown-source' as any]: colourUnknown,
    ['--colour-code-source' as any]: colourCode,
    ['--colour-data-source' as any]: colourData,
    ['--colour-graphics-source' as any]: colourGfx
}

const badGuidMessage = "That project doesn't exist";
const minimumLoadingDurationMs = 1000;

export const projectLoader = async (args: LoaderFunctionArgs) => {

    // Pull a guid from the url
    const { guid: guidParam } = args.params;

    // This should never happen, but...
    if (guidParam == null) { throw new Error(badGuidMessage) };

    // Is this a magic guid?
    if (guidParam === 'test') {
        const { commands, RAM, COLRAM } = PrepareCommands();
        const description = newProjectDescription('TEST DATA', makeGuid(), 'private', RAM, COLRAM, commands);
        return { description, isCloud: false };
    }

    // Check guid is well-formed
    if (!checkGuid(guidParam)) { throw new Error(badGuidMessage) };

    logInfo(`Loading new guid ${guidParam}`);

    // Want to take at least 1 second to load, to show loading screen
    // This gets awaited at the end of the function
    const minimumLoadingDurationTimeout = new Promise(r => setTimeout(r, minimumLoadingDurationMs));

    // Start looking for a project which matches the supplied guid
    let description: ProjectDescription | undefined;
    let isCloud: boolean = true;

    // Try to load from local storage
    try {
        if (exists(guidParam)) {
            logInfo(`Looking for local file with guid ${guidParam}`);
            const fileLocal = await getAsyncLocal(guidParam);
            if (fileLocal != null) {
                logInfo(`Found local file with guid ${guidParam}`);
                description = fileLocal;
                isCloud = false;
            }
        }
    }
    catch (error) {
        if (!(error instanceof Error)) { throw error }
        logError(`${error.name} : ${error.message}`);
    }

    // If that didn't work, try to load from cloud storage
    if (description == null) {
        try {
            logInfo(`Looking for cloud file with guid ${guidParam}`);
            const fileCloud = await getAsyncCloud(guidParam);
            if (fileCloud != null) {
                logInfo(`Found cloud file with guid ${guidParam}`);
                description = fileCloud;
                isCloud = true;
            }
        }
        catch (error) {
            if (!(error instanceof Error)) { throw error }
            logError(`${error.name} : ${error.message}`);
        }
    }

    // Otherwise there's a problem with the guid
    if (description == null) {
        logInfo(`Didn't find a file with guid ${guidParam}`);
        throw (new Error(badGuidMessage));
    }

    // Wait for the minimum loading time to pass
    await minimumLoadingDurationTimeout;

    logInfo(`Returning description from ${isCloud ? 'cloud' : 'local'} file with guid ${guidParam}`);
    return { description, isCloud };
};


export const ProjectPageRoot: React.FC = () => {

    logMajorComponentRender(ProjectPageRoot.name)

    const { description, isCloud } = useLoaderData() as { description: ProjectDescription, isCloud: boolean };

    const dispatch = useAppDispatch();
    const guidProject = useAppSelector(selectGuid);

    useEffect(() => {
        // If the guid from the loader is different to the project guid, update the store with the
        // newly-loaded project
        if (description == null) { return; }
        if (description.guid === guidProject) { return; }

        dispatch(descriptionSet({ description, isCloud }));

    }, [description, isCloud, guidProject, dispatch]);

    return <Outlet />;
}


export const ProjectPage: React.FC<{ toolParam: string, memoryViewParam?: string }> = ({ toolParam, memoryViewParam }) => {

    logMajorComponentRender(ProjectPage.name)

    const activeTool = useAppSelector(selectActiveTool);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { guid, address } = useParams();

    const activeMemoryView = useAppSelector(selectActiveMemoryView);

    const isReadOnly = useAppSelector(selectIsProjectReadOnly);

    // Keep store updated with route
    useEffect(() => {
        if (toolParam === 'graphics' && isReadOnly) {
            setRoute(navigate, location, { guid, tool: 'library' }, false);
            return;
        }

        if (toolParam !== activeTool) {
            dispatch(activeToolSet(toolParam as ToolTypes));
        }
        if (memoryViewParam != null && memoryViewParam !== activeMemoryView) {
            dispatch(activeMemoryViewSet(memoryViewParam as MemoryViewType));
        }

        // Update page title from route
        const addressString = (address == null) ? '' : ` @ ${address}`;
        const route = (toolParam === 'code') ? `code${addressString} (${memoryViewParam})` : `${toolParam}`;
        const title = `46c - ${route}`;
        if (window.document.title !== title) {
            window.document.title = title;
        }

    }, [toolParam, activeTool, memoryViewParam, address, activeMemoryView, dispatch, guid, isReadOnly, navigate, location]);

    const sizes = isReadOnly ? 'auto 1fr' : 'auto auto 1fr';

    return (
        <Stack layout='vertical' sizes={sizes} style={sourceColoursStyle} cssClassName={styles.projectPage}>

            {/* Tabs */}
            <Stack layout='horizontal' alignItems='center' sizes={'auto 1fr auto auto'} cssClassName={styles.tabsPanel}>
                <ProjectNamePanel />
                <TabsPanel />
                <SavePanel />
            </Stack>

            {/* Settings */}
            {!isReadOnly && <SettingsPanel />}

            {/* Main */}
            <Stack layout='horizontal' sizes={'1fr auto'}>

                <MainPanel />

                {/* MemoryMap */}
                <GraphicsViewport location={ViewportLocation.MemoryMap} ignoreScrollbars={{ h: true, v: true }} show={{ marked: false, selected: true, grid: false }} render={r =>
                    <div ref={r}>
                        <MemoryMap />
                    </div>
                } />
            </Stack>

        </Stack>
    );
}