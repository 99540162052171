import { MouseEvent, useState } from "react";
import { Stack } from "./Stack";
import { Modal } from "./SettingsPanel/Modal";
import { ProjectPicker } from "./SettingsPanel/ProjectPicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faSignOutAlt, faWarning } from '@fortawesome/free-solid-svg-icons';
import styles from './MenuPanel.module.css';
import { useAppSelector } from "../store/hooks";
import { selectProjectStatus } from "../store/projectSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { setRouteHome } from "../App";

export const MenuPanel: React.FC = () => {

    const navigate = useNavigate();
    const [filePickerModalIsOpen, SetFilePickerModalIsOpen] = useState<boolean>(false);
    const [exitConfirmationModalIsOpen, SetExitConfirmationModalIsOpen] = useState<boolean>(false);

    const { dirty } = useAppSelector(selectProjectStatus);

    const { pathname } = useLocation();
    const isHome = pathname.indexOf('project') < 0;

    const handleOpenFilePicker = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        SetFilePickerModalIsOpen(true);
    }

    const handleCloseFilePickerModal = () => SetFilePickerModalIsOpen(false);

    const handleCloseExitConfirmationModal = () => SetExitConfirmationModalIsOpen(false);

    const handleClickExit = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (!dirty) {
            setRouteHome(navigate);
        }
        else {
            SetExitConfirmationModalIsOpen(true);
        }
    };

    const handleConfirmClickHome = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        SetExitConfirmationModalIsOpen(false);
        setRouteHome(navigate);
    }

    return (
        <Stack layout='horizontal' alignItems='center' justifyContent='start' cssClassName={styles.panel}>

            {!isHome &&
                <div style={{ position: 'relative' }}>
                    <button className={styles.tab} onClick={handleClickExit}>
                        <FontAwesomeIcon className={styles.icon} icon={faSignOutAlt} flip='horizontal' />
                        <span>Exit</span>
                    </button>

                    {exitConfirmationModalIsOpen &&
                        <Modal onClose={handleCloseExitConfirmationModal} align='bottom-left'>
                            <div className={styles.popup}>
                                <p><FontAwesomeIcon icon={faWarning} /> Exiting now will lose unsaved changes</p>
                                <Stack layout='horizontal' justifyContent='end'>
                                    <button onClick={handleCloseExitConfirmationModal} className={styles.button}>Cancel</button>
                                    <button onClick={handleConfirmClickHome} className={styles.button}>Exit</button>
                                </Stack>
                            </div>
                        </Modal>
                    }
                </div>
            }

            <div style={{ position: 'relative' }}>
                <button className={styles.tab} onClick={handleOpenFilePicker}>Project <FontAwesomeIcon className={styles.icon} icon={faChevronDown} /></button>
                {filePickerModalIsOpen &&
                    <Modal onClose={handleCloseFilePickerModal} align='bottom-left' heightPx={670}>
                        <ProjectPicker onClose={handleCloseFilePickerModal} />
                    </Modal>
                }
            </div>
        </Stack>

    );
}