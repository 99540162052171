import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { ProjectDescription, ProjectVisibility, makeGuid } from "../classes/ProjectDescription";
import { Command } from "../classes/commands/types";
import { FormattingCommand } from "../classes/commands/FormattingCommand";
import { newFormattingCommand, markUpMemory as markUpMemoryFromFormattingCommand } from "../classes/commands/FormattingCommandHelpers";
import { RootState } from ".";
import { Utils } from "../classes/Utils";
import { GraphicCommand } from "../classes/commands/GraphicCommand";
import { EntryPointCommand } from "../classes/commands/EntryPointCommand";
import { DataCommand } from "../classes/commands/DataCommand";
import { PointerCommand } from "../classes/commands/PointerCommand";
import { LabelCommand } from "../classes/commands/LabelCommand";
import { MemoryLocation, MemoryAddress } from "../classes/code/MemoryLocation";
import { markUpMemory as markupFromDataCommand } from "../classes/commands/DataCommandHelpers";
import { EntryPointData, getROM, MemoryManager, Note } from "../classes/code/MemoryManager";
import { Range } from "../classes/code/Range";
import { markUpMemory as markupFromPointerCommand } from '../classes/commands/PointerCommandHelpers';
import { cloneGraphicCommand, markUpMemory as markupFromGraphicCommand } from "../classes/commands/GraphicCommandHelpers";
import { markUpMemory as markupFromLabelCommand } from '../classes/commands/LabelCommandHelpers';
import { markUpMemory as markupFromCommentCommand } from '../classes/commands/CommentCommandHelpers';
import { markUpMemory as markupFromOverrideCommand } from '../classes/commands/OverrideCommandHelpers';
import { logReducer, logSelector, logWarning } from "../classes/Logger";
import { CommentCommand } from "../classes/commands/CommentCommand";
import { OverrideCommand } from "../classes/commands/OverrideCommand";


export type ProjectState = {
    name: string;
    guid: string;
    RAM: number[];
    COLRAM: number[];
    commands: Command[];
    isCloud: boolean;
    visibility: ProjectVisibility,
    uid?: string,
    userName?: string,
    dirty: boolean;
    currentCommandIndex?: number;
}

const initialState: ProjectState = {
    name: '',
    guid: makeGuid(),
    RAM: new Array(0x10000).fill(0),
    COLRAM: new Array(0x03e8).fill(0),
    commands: [],
    isCloud: false,
    visibility: 'private',
    uid: undefined,
    userName: undefined,
    dirty: false,
}

const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        nameSet(state, action: PayloadAction<string>) {
            logReducer('projectSlice', 'nameSet');
            state.name = action.payload;
            state.dirty = true;
        },

        guidSet(state, action: PayloadAction<string>) {
            logReducer('projectSlice', 'guidSet');
            state.guid = action.payload;
            state.dirty = true;
        },

        dirtySet(state, action: PayloadAction<boolean>) {
            logReducer('projectSlice', 'dirtySet');
            state.dirty = action.payload;
        },

        visibilitySet(state, action: PayloadAction<ProjectVisibility>) {
            logReducer('projectSlice', 'visibilitySet');
            state.visibility = action.payload;
            state.dirty = true;
        },

        descriptionSet(state, action: PayloadAction<{ description: ProjectDescription, isCloud: boolean }>) {
            logReducer('projectSlice', 'descriptionSet');
            const { description } = action.payload;
            state.name = description.name;
            state.guid = description.guid;
            state.RAM = Array.from(description.RAM);
            state.COLRAM = Array.from(description.COLRAM);
            state.commands = description.commands;
            state.isCloud = action.payload.isCloud;
            state.visibility = description.visibility;
            state.uid = description.uid;
            state.userName = description.userName;
            state.dirty = false;
        },

        memorySet(state, action: PayloadAction<{ RAM: Uint8Array, COLRAM: Uint8Array }>) {
            logReducer('projectSlice', 'memorySet');
            state.RAM = Array.from(action.payload.RAM);
            state.COLRAM = Array.from(action.payload.COLRAM);
            state.dirty = true;
        },

        commandAdded(state, action: PayloadAction<Command>) {
            logReducer('projectSlice', 'commandAdded');
            state.commands.push(action.payload);
            state.dirty = true;
        },

        commandsAdded(state, action: PayloadAction<Command[]>) {
            logReducer('projectSlice', 'commandsAdded');
            state.commands.push(...action.payload);
            state.dirty = true;
        },

        commandUpdated(state, action: PayloadAction<{ commandIndexToUpdate: number, command: Command }>) {
            logReducer('projectSlice', 'commandUpdated');
            state.commands[action.payload.commandIndexToUpdate] = action.payload.command;
            state.dirty = true;
        },

        commandUpdatedById(state, action: PayloadAction<{ commandIdToUpdate: number, command: Command }>) {
            logReducer('projectSlice', 'commandUpdatedById');
            const { commandIdToUpdate, command: sourceCommand } = action.payload;

            const updatedCommand = cloneGraphicCommand(sourceCommand as GraphicCommand);
            updatedCommand.id = commandIdToUpdate;

            const index = state.commands.findIndex(c => c.type === 'graphic' && (c as GraphicCommand).id === commandIdToUpdate);
            state.commands[index] = updatedCommand;
            state.dirty = true;
        },

        commandRemoved(state, action: PayloadAction<number>) {
            logReducer('projectSlice', 'commandRemoved');
            state.commands.splice(action.payload, 1);

            state.dirty = true;
        },

        commandRemovedById(state, action: PayloadAction<number>) {
            const idToRemove = action.payload;
            logReducer('projectSlice', 'commandRemovedById', `with id ${idToRemove}`);

            const index = state.commands.findIndex(c => c.type === 'graphic' && (c as GraphicCommand).id === idToRemove);

            state.commands.splice(index, 1);

            if (idToRemove === state.currentCommandIndex) {
                state.currentCommandIndex = undefined;
            }

            state.dirty = true;
        },

        currentCommandIndexSet(state, action: PayloadAction<number | undefined>) {
            logReducer('projectSlice', 'currentCommandIndexSet', `with payload ${action.payload}`);
            state.currentCommandIndex = action.payload;
        },

        formattingToggled(state, action: PayloadAction<{ address: number, toggleType: boolean }>) {
            logReducer('projectSlice', 'formattingToggled');
            const { address, toggleType } = action.payload;

            const existingCommandIndex = state.commands.findIndex(c => c.type === 'formatting' && (c as FormattingCommand).address === address);

            if (existingCommandIndex === -1) {
                state.commands.push(newFormattingCommand(address, toggleType ? 'gap' : 'line-break'));
            } else {
                if (!toggleType) {
                    state.commands.splice(existingCommandIndex, 1);
                } else {
                    const toggledType = ((state.commands[existingCommandIndex] as FormattingCommand).format === 'line-break') ? 'gap' : 'line-break';
                    (state.commands[existingCommandIndex] as FormattingCommand).format = toggledType;
                }
            }

            state.dirty = true;
        },

        formattingCleared(state, action: PayloadAction<{ startAddress: number, endAddress: number }>) {
            logReducer('projectSlice', 'formattingCleared');
            const { startAddress, endAddress } = action.payload;
            state.commands = state.commands
                .filter(c => !((c.type === 'formatting') && (c.address >= startAddress && c.address <= endAddress)));

            state.dirty = true;
        }
    }
})

export const { nameSet, commandAdded, commandRemoved, commandRemovedById, commandUpdated, commandUpdatedById, commandsAdded, currentCommandIndexSet, descriptionSet, dirtySet, visibilitySet, formattingCleared, formattingToggled, guidSet, memorySet } = projectSlice.actions;
export default projectSlice.reducer;

export const selectGuid = (state: RootState) => state.project.guid;

export const selectGuidAndName = createSelector(
    (state: RootState) => state.project.guid,
    (state: RootState) => state.project.name,
    (guid, name) => {
        logSelector('projectSlice', 'selectGuidAndName');
        return { guid, name };
    }
)

export const selectRAM = (state: RootState) => state.project.RAM;
export const selectCOLRAM = (state: RootState) => state.project.COLRAM;
export const selectROM = createSelector(
    selectCOLRAM,
    (colramBytes) => {
        return getROM(colramBytes);
    }
)
export const selectCommands = (state: RootState) => state.project.commands;

export const selectProjectName = (state: RootState) => state.project.name;

export const selectProjectStatus = createSelector(
    (state: RootState) => state.project.isCloud,
    (state: RootState) => state.project.visibility,
    (state: RootState) => state.project.dirty,
    (state: RootState) => state.project.uid,
    (state: RootState) => state.project.userName,
    (isCloud, visibility, dirty, uid, userName) => {
        logSelector('projectSlice', 'selectProjectStatus');
        return { isCloud, visibility, dirty, uid, userName };
    }
)

export const selectCurrentCommandIndex = (state: RootState) => state.project.currentCommandIndex;

export const selectIndexedCommands = createSelector(
    selectCommands,
    commands => {
        logSelector('projectSlice', 'selectIndexedCommands');
        return commands
            .map((c, i) => { return { index: i, command: c } })
    }
)

export const selectIndexedGraphicsCommands = createSelector(
    selectCommands,
    commands => {
        logSelector('projectSlice', 'selectIndexedGraphicsCommands');
        return commands
            .map((c, i) => { return { index: i, command: c as GraphicCommand } })
            .filter(c => c.command.type === 'graphic')
    }
)

export const selectIndexedEntryPointCommands = createSelector(
    selectCommands,
    commands => {
        logSelector('projectSlice', 'selectIndexedEntryPointCommands');
        return commands
            .map((c, i) => { return { index: i, command: c as EntryPointCommand } })
            .filter(c => c.command.type === 'entry-point')
    }
)

export const selectIndexedDataCommands = createSelector(
    selectCommands,
    commands => {
        logSelector('projectSlice', 'selectIndexedDataCommands');
        return commands
            .map((c, i) => { return { index: i, command: c as DataCommand } })
            .filter(c => c.command.type === 'data')
    }
)

export const selectIndexedFormattingCommands = createSelector(
    selectCommands,
    commands => {
        logSelector('projectSlice', 'selectIndexedFormattingCommands');
        return commands
            .map((c, i) => { return { index: i, command: c as FormattingCommand } })
            .filter(c => c.command.type === 'formatting')
    }
)

export const selectIndexedOverrideCommands = createSelector(
    selectCommands,
    commands => {
        logSelector('projectSlice', 'selectIndexedOverrideCommands');
        return commands
            .map((c, i) => { return { index: i, command: c as OverrideCommand } })
            .filter(c => c.command.type === 'override')
    }
)

export const selectIndexedPointerCommands = createSelector(
    selectCommands,
    commands => {
        logSelector('projectSlice', 'selectIndexedPointerCommands');
        return commands
            .map((c, i) => { return { index: i, command: c as PointerCommand } })
            .filter(c => c.command.type === 'pointer')
    }
)

export const selectIndexedLabelCommands = createSelector(
    selectCommands,
    commands => {
        logSelector('projectSlice', 'selectIndexedLabelCommands');
        return commands
            .map((c, i) => { return { index: i, command: c as LabelCommand } })
            .filter(c => c.command.type === 'label')
    }
)

export const selectIndexedCommentCommands = createSelector(
    selectCommands,
    commands => {
        logSelector('projectSlice', 'selectIndexedCommentCommands');
        return commands
            .map((c, i) => { return { index: i, command: c as CommentCommand } })
            .filter(c => c.command.type === 'comment')
    }
)

export const selectMarkedUpMemory = createSelector(
    selectRAM,
    selectROM,
    selectCommands,
    (ramBytes, { rom: romSource, romRanges: romRangesSource }, commands) => {
        logSelector('projectSlice', 'selectMarkedUpMemory');

        // Make a copy of the ROM locations and ranges, so that new references from disassambled code can be added safely
        const rom = romSource.map(m => m.clone());
        const romRanges = [...romRangesSource];

        const ram = Array<MemoryLocation>(0x10000);
        for (let index = 0; index < ram.length; index++) {
            ram[index] = new MemoryLocation({ type: 'ram', address: index }, ramBytes[index]);
        }

        const ramRanges = new Array<Range>();
        commands
            .filter(c => ['data', 'entry-point', 'formatting', 'graphic', 'label', 'pointer', 'comment', 'override'].indexOf(c.type) > -1)
            .forEach(c => {
                if (c.type === 'data') {
                    markupFromDataCommand(c as DataCommand, ram, ramRanges)
                } else if (c.type === 'entry-point') {

                } else if (c.type === 'formatting') {
                    markUpMemoryFromFormattingCommand(c as FormattingCommand, ram, ramRanges)
                } else if (c.type === 'graphic') {
                    markupFromGraphicCommand(c as GraphicCommand, ram, ramRanges);
                } else if (c.type === 'label') {
                    markupFromLabelCommand(c as LabelCommand, ram, ramRanges);
                } else if (c.type === 'pointer') {
                    markupFromPointerCommand(c as PointerCommand, ram, rom);
                } else if (c.type === 'comment') {
                    markupFromCommentCommand(c as CommentCommand, ram, ramRanges);
                } else if (c.type === 'override') {
                    markupFromOverrideCommand(c as OverrideCommand, ram, ramRanges);
                }
            });

        const entrypoints: MemoryAddress[] = commands.filter(c => c.type === 'entry-point').map(c => (c as EntryPointCommand).address).map(e => { return { type: 'ram', address: e } });
        const entryPointsData: EntryPointData[] = [];
        const notes: Note[] = [];
        const durationMS = new MemoryManager().disassemble(ram, rom, ramRanges, romRanges, entrypoints, entryPointsData, notes);

        notes
            .filter(n => n.type === 'warning' || n.type === 'error')
            .map(n => logWarning(`$${Utils.to4DigitHexString(n.address.address)}-${n.address.type} : [${n.type}] ${n.note}`));

        return { ram, rom, ramRanges, romRanges, entryPointsData, durationMS };
    }
)
